import "./Contactinformation.css";
export function Contactinformation(props) {
  return (
    <div className="contactInformationParagraph">
      <p className="contactInformationParagraphp">
        {props.ContactinformationParagraph}
      </p>
    </div>
  );
}
